@font-face {
    font-family: 'Frutiger Neue LT W1G Light';
    src: url('subset-FrutigerNeueLTW1G-Light.woff2') format('woff2'),
        url('subset-FrutigerNeueLTW1G-Light.woff') format('woff'),
        url('subset-FrutigerNeueLTW1G-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Frutiger Neue LT W1G Black';
    src: url('subset-FrutigerNeueLTW1G-Black.woff2') format('woff2'),
        url('subset-FrutigerNeueLTW1G-Black.woff') format('woff'),
        url('subset-FrutigerNeueLTW1G-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Frutiger Neue LT W1G Thin';
    src: url('subset-FrutigerNeueLTW1G-Thin.woff2') format('woff2'),
        url('subset-FrutigerNeueLTW1G-Thin.woff') format('woff'),
        url('subset-FrutigerNeueLTW1G-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Frutiger Neue LT W1G UltLt';
    src: url('subset-FrutigerNeueLTW1G-UltLt.woff2') format('woff2'),
        url('subset-FrutigerNeueLTW1G-UltLt.woff') format('woff'),
        url('subset-FrutigerNeueLTW1G-UltLt.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Frutiger Neue LT W1G Regular';
    src: url('subset-FrutigerNeueLTW1G-Regular.woff2') format('woff2'),
        url('subset-FrutigerNeueLTW1G-Regular.woff') format('woff'),
        url('subset-FrutigerNeueLTW1G-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Frutiger Neue LT W1G Bold';
    src: url('subset-FrutigerNeueLTW1G-Bold.woff2') format('woff2'),
        url('subset-FrutigerNeueLTW1G-Bold.woff') format('woff'),
        url('subset-FrutigerNeueLTW1G-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Frutiger Neue LT W1G Heavy';
    src: url('subset-FrutigerNeueLTW1G-Heavy.woff2') format('woff2'),
        url('subset-FrutigerNeueLTW1G-Heavy.woff') format('woff'),
        url('subset-FrutigerNeueLTW1G-Heavy.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Frutiger Neue LT W1G Medium';
    src: url('subset-FrutigerNeueLTW1G-Medium.woff2') format('woff2'),
        url('subset-FrutigerNeueLTW1G-Medium.woff') format('woff'),
        url('subset-FrutigerNeueLTW1G-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

