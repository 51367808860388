body {
  margin: 0;
  background: #e6f1f7;
  min-height: 100vh;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body, textarea, input, select {
  font-family: "Frutiger Neue LT W1G Regular", sans-serif;
}

* {
  box-sizing: border-box;
}